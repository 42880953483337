.quotes-container {
    background-color: #D4F1F4;
    padding-top: 10px;

    .quotes-col {
        margin-left: auto;
        margin-right: auto;

        h5 {
            color: #05445E;
        }

        p{
            color: #189AB4;
        }
    }
}