.wishlist-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.goto-cart-button-span {
    // margin: 0;
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    button{
      background-color: #6c757d;
    }
}

.animate-charcter
{
    text-transform: uppercase;
    background-image: linear-gradient(
        -225deg,
        #231557 0%,
        #44107a 29%,
        #ff1361 67%,
        #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    // text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: auto;
}