.btn-text-size {
    display: block; 
    margin: 0 auto;
    width: 50%;
}

.span-align {
    display:table; 
    margin:auto; 
    margin-top: 10px;
    padding: 0 10px;
    color: #fff;
    font-size: larger;
    font-weight: 600;
    letter-spacing: 1px;
    outline: 3px double rgb(216 219 221);
    background-color: #189AB4;
}

.animate-charcter
{
    text-transform: uppercase;
    background-image: linear-gradient(
        -225deg,
        #231557 0%,
        #44107a 29%,
        #ff1361 67%,
        #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    // text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: auto;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}