// Set background image (pattern)

*{
    -ms-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  html, body{
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
  }

  // Start styles in form
  
  .login{
    // position: relative;
    top: 50%;
    width: 250px;
    height: 65vh;
    display: table;
    margin: 50px auto 50px auto;
    background: #fff;
    border-radius: 4px;
  }
  
  .legend{
    position: relative;
    width: 100%;
    display: block;
    // background: #FF7052;
    background: #A9A9A9;
    padding: 15px;
    color: #fff;
    font-size: 20px;
    
    &:after{
      content: "";
      background-image: url(./../../assets/multy-user.png);
      background-size: 100px 100px;
      background-repeat: no-repeat;
      background-position: 152px -16px;
      opacity: 0.06;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
  
  .input{
    position: relative;
    width: 90%;
    margin: 15px auto;
    
    span{
      position: absolute;
      display: block;
      color: darken(#EDEDED, 10%);
      left: 10px;
      top: 8px;
      font-size: 20px;
    }
    
    input{
      width: 100%;
      padding: 10px 5px 10px 40px;
      display: block;
      border: 1px solid #EDEDED;
      border-radius: 4px;
      transition: 0.2s ease-out;
      color: darken(#EDEDED, 30%);
      
      &:focus{
        padding: 10px 5px 10px 10px;
        outline: 0;
        border-color: #A9A9A9;
      }
    }
  }
  
  .submit{
    width: 45px;
    height: 45px;
    display: block;
    margin: 0 auto -15px auto;
    background: #fff;
    border-radius: 100%;
    border: 1px solid #A9A9A9;
    color: #A9A9A9;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 7px #fff;
    transition: 0.2s ease-out;
    margin-top: 30px;
    
    &:hover, &:focus{
      background: #A9A9A9;
      color: #fff;
      outline: 0;
    }
  }
  
  .feedback{
    position: absolute;
    bottom: -70px;
    width: 100%;
    text-align: center;
    color: #fff;
    background: #2ecc71;
    padding: 10px 0;
    font-size: 12px;
    display: none;
    opacity: 0;
    
    &:before{
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(46, 204, 113, 0);
      border-bottom-color: #2ecc71;
      border-width: 10px;
      margin-left: -10px;
      
    }
  }