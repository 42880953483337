.sign-in-and-sign-up {
    width: 850px;
    display: flex;
    justify-content: space-between;
    margin:  30px auto;

    @media screen and (max-width: 800px) {
        display: unset;
      }
}

.sign-in-and-sign-up-page {
  margin-top: 30px;
  margin-bottom: 10px;
}