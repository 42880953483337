@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
  }
  
  .loading-dots {
    text-align: center;
    width: 100%;
    
    &--dot {
      animation: dot-keyframes 1.5s infinite ease-in-out;
      background-color: #4285f4;
      border-radius: 10px;
      display: inline-block;
      height: 10px;
      width: 10px;
      
      &:nth-child(2) {
        animation-delay: .5s;
      }
      
      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  }

  .loading-dots--dot {
    margin: 2px;
  }