.privacy-container {
    padding-bottom: 30px;
    p {
        text-align: justify;

        a {
            font-weight: 800;
        }
        a:hover {
            color: #1aa5d8;
        }
    }
}