.price {
  font-size: large;
  font-weight: bold !important;
}

.add-button {
  display: flex;
  justify-content: end;
}

.align {
  display: flex;
  align-items: center;
  margin: 0;
  span {
    font-size: 16px;
    font-weight: bold !important;
  }
}

.border-align {
  border: groove;
  margin: 0;
  margin-top: 10px;
  border-radius: 5px;
}

.align-center {
  text-align: center;
}
.align-price {
  text-align: right;
  font-weight: bold !important;
}

.btn-text-size-description {
  display: block;
  margin: 10px auto;
  font-size: 12px;
  width: auto;
  background-color: #05445e;
  border: none;
}

.btn-text-size-description:hover {
  background-color: #43484d;
}

@media (min-width: 320px) and (max-width: 360px) {
  .price {
    font-size: small;
  }
  .add-button button {
    opacity: 0.9;
    min-width: unset;
    padding: 0 10px;
    font-size: small;
    width: auto;
  }
}

.border-align-inside-box {
  border: outset;
  margin: 0 0 10px 0;
  margin-top: 10px;
  border-radius: 5px;
  padding: 0;
}
