body{
    transition: background-color .5s;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a, .sidenav span {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover, .sidenav span:hover {
  color: #f1f1f1;
  cursor:pointer;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.right-panel {
  transition: margin-left .5s;
  padding: 16px;
}

.side-nav-span {
    font-size:30px;
    cursor:pointer;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
  .sidenav span {font-size: 18px;}
}

  .add-width {
      width: 250px;
  }
  .no-width {
      width: 0px;
  }

  .add-left-margin {
    margin-left: 250px;
  }

  .no-left-margin {
    margin-left: 0;
    padding: 0rem;
  }