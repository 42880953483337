.sign-up{
    display: flex;
    flex-direction: column;
    width: 380px;
    margin-left: auto;
    margin-right: auto;

    .title {
        display: flex;
        margin: 10px 0px;

        span {
            display: flex;
        }
    }

    @media (min-width: 320px) and (max-width: 360px) {
        padding: 1.75rem;
        width: auto;
        .buttons{
            button {
                padding: 0.25rem;
            }
        }
    }
    @media screen and (max-width: 800px) {
        padding: 2rem;
        width: auto;
        .buttons{
            button {
                padding: 0.25rem;
            }
        }
    }
}