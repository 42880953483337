// .header {
//     height: 35%;
//     width: 120%;
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 25px;
  
//     .logo-container {
//       height: 120%;
//       width: 35%;
//       padding: 25px;
//     }
  
//     .options {
//       width: 50%;
//       height: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: flex-end;
  
//       .option {
//         padding: 10px 15px;
//         cursor: pointer;
//       }
//     }
//   }

// // .header-menu {
// //   display: flex;
// //   background: #6c757d;
// //   // .active,a:hover{
// //   //   transition: .5s;
// //   //   background-color: #E8E8E8;
// //   // }
// // }
// // .header-menu {
// //   ul{
// //     text-decoration: none;
// //     list-style: none;
// //     box-sizing: border-box;
// //     margin-bottom: 0;
// //     width: 100%;
// //     height: 10%;
// //     justify-content:flex-end;
// //     align-items: center;
// //     li {
// //       // display: inline-block;
// //       line-height: 80px;
// //       margin: 0 5px;
// //     }
// //   }
// // }
// // .checkBtn {
// //   font-size: 30px;
// //   float: right;
// //   line-height: 80px;
// //   cursor: pointer;
// // }

// // #check{
// //   display: none;
// // }

// .cart{
//   // padding-left: 10px;
// }
// .header-menu {
//   // background: #6c757d;
//   background: linear-gradient(to bottom, 
//   #6c757d 33%,
//   #899197 66%,
//   #A1A7AC 100%,
//   );
//   box-sizing: border-box;
//   // box-shadow: 0px 3px #DADCDE;
// }

// .menu{
//   display: flex;
//   flex-wrap: wrap;
//   list-style-type: none;
//   justify-content: space-between;
//   padding: 0 15px;
//   align-items: center;
//   margin: 0;
// }
// .link{
//   color: white;
//   text-decoration: none;
//   display: block;
//   padding: 15px 5px;
// }
// .bar{
//   color: white;
// }
// .logo{
//   padding: 7.5px 10px 7.5px 0;
// }

// .item{
//   padding: 10px;
//   text-transform: uppercase;
//   font-size: 18px;
// }

// .link:hover,
// .link:hover::after
// {
//   color: #DCDCDC;
// }

// .toggle {
//   order: 1;
//   font-size: 30px;
// }

// .item {
//   order: 2;
//   width: 100%;
//   text-align: center;

//   display: none;
// }

// .active .item {
//   // display: block;
//   display: flex;
//   justify-content: center;
// }
// .cart-list {
//   margin-top: -10px;
// }

// @media all and (min-width: 320px){
//     .menu{
//        justify-content: center;
//     }

//     .logo{
//       flex: 1;
//     }

//     .toggle {
//        flex: 1;
//        text-align: right;
//        order: 2;
//     }
// }

// @media all and (min-width: 960px) {
//   .item {
//     order: 1;
//     position: relative;
//     display: block;
//     width: auto;
//   }

//   .toggle{
//     display: none;
//   }
// }

// li {
//   list-style-type: none;
//   display: inline-block;
//   margin: 5px 10px;

// }

// li > .link {
//   // color: #333;
//   text-decoration: none;
//   display: inline-block;
//   position: relative;
// }

// li > .link::after {
//   content: "";
//   display: block;
//   margin: auto;
//   height: 3px;
//   width: 0;
//   top: 5px;
//   background: transparent;
//   transition: all 0.3s;
  
// }

// // li > .link:hover::after, 
// li > .link.active-nav::after {
//   width: 100%;
//   background: #1aa5d8;
// }

// .user-name-display {
//   color: #DCDCDC;
//   text-transform: capitalize;
// }

// .nav-search-icon:hover {
//     cursor: pointer;
// }


// New Code

.header-div {
    background: #f2f2f2;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 1000;
}

ul {
    padding-left: 0;
    padding-bottom: 0;
}
nav {
    // background: #1b1b1b;
    background: #6c757d;
}

nav .logo {
    float: left;
    color: white;
    font-size: 27px;
    font-weight: 600;
    line-height: 70px;
    padding-left: 60px;
}
nav:after {
    content: '';
    clear: both;
    display: table;
}

nav ul {
    float: right;
    list-style: none;
    margin-right: 40px;
    position: relative;
    top: 10px;
}

nav ul li {
    float: left;
    display: inline-block;
    // background: #1b1b1b;
    background: #6c757d;
    margin: 0 5px;
    margin-bottom: -16px;
}

nav ul li a {
    color: white;
    text-decoration: none;
    line-height: 70px;
    font-size: 18px;
    padding: 8px 15px;
    text-transform: uppercase;
}

nav ul li a:hover {
    // box-shadow: 0 0 5px #33ffff, 0 0 5px #66ffff;
    // color: cyan;
    box-shadow: 0 0 5px #1ad2d8, 0 0 5px #1ad8d8;
    color:#1ad1d8;
    border-radius: 5px;

}
nav ul ul {
    position: absolute;
    top: 90px;
    // border-top: 3px solid cyan;
    border-top: 3px solid #1ad1d8;
    opacity: 0;
    visibility: hidden;
    transition: top 0.3s;
}

nav ul ul ul {
    border-top: none;
}

nav ul li:hover > ul {
    top: 70px;
    opacity: 1;
    visibility: visible;
}
nav ul ul li {
    position: relative;
    margin: 0px;
    width: '-webkit-fill-available';
    float: none;
    display: list-item;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}

nav ul ul li a {
    line-height: 50px;
}

nav ul ul li a:hover {
    // color: cyan;
    color: #1ad1d8;
    box-shadow: none;

}

nav ul ul ul li {
    position: relative;
    top: -70px;
    left: 150px;
}

.fa-plus {
    margin-left: 40px;
    font-size: 15px;
}

.show,.icon, #btn, #btn-1, #btn-2, #btn-3, #btn-4 {
    display: none;
}

.cart-list > div {
    margin-top: 10px;
}

.cart-list > i {
    margin-top: 27px;
    cursor: pointer;
}

.user-name-display {
    color: white;
    text-align: center;
}

@media all and (max-width: 968px) {
    .header-ul {
        margin-bottom: 30px;
    }

    nav ul {
        margin-right: 0px;
        float: left;
    }
    nav .logo {
        padding-left: 30px;
        width: 100%;

    }

    nav ul li, nav ul ul li {
        display: block;
        width: 98%;
    }
    nav ul li i {
        padding-left: 12px;
    }

    nav ul li i {
        padding-left: 12px;
    }

    .divider-height {
        height: 0px;
    }

    .mrg-btm-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    nav ul ul {
        top: 70px;
        position: static;
        border-top: none;
        float: none;
        display: none;
        opacity: 1;
        visibility: visible;
    }

    nav ul ul ul li{
        position: static;
    }

    nav ul ul li {
        border-bottom: 0px;
    }

    nav ul ul a{
        padding-left: 40px;
    }
    nav ul ul ul a{
        padding-left: 80px;
    }

    .show {
        display: block;
        color: white;
        text-transform: uppercase;
        font-size: 18px;
        padding: 0 15px;
        line-height: 70px;
        cursor: pointer;
    }

    .show:hover {
        color: cyan
    }

    .icon{

        display: block;
        color: white;
        position: absolute;
        top:5px;
        right: 40px;
        line-height: 70px;
        font-size: 25px;
        cursor: pointer;
    }

    nav ul li a:hover {
        box-shadow: none;
    }

    .show + a, .header-ul {
        display: none;
    }

    .more{
        padding-left: 40px;
    }

    [id^=btn]:checked + ul {
        display: block;
    }
}