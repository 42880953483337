.back-to-login {
    text-align: center;
    a {
        color: grey;
        font-weight: 600;
    }
    a:hover {
        text-decoration: underline;
    }
    
}