.checkout-page {
    width: auto;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 50px auto 0;

    @media screen and (max-width: 800px) {
      // width: 80vw;
      font-size: 14px;
      padding: 1.25rem 2rem;
    }
  
    .checkout-header {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid darkgrey;
  
      .header-block {
        text-transform: capitalize;
        width: 23%;
  
        &:last-child {
          width: 8%;
        }
      }
    }
  
    .total {
      margin-top: 30px;
      margin-left: auto;
      font-size: 36px;
    }

    button {
      margin-left: auto;
      margin-top: 50px;
    }

    .test-warning {
      text-align: center;
      margin-top: 40px;
      font-size: 24px;
      color: red;
    }
}

.test-warning {
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  color: red;
  display: flex;
  justify-content: center;
}
.set-margin{
  margin: 0;
}
.total {
  margin-top: 30px;
  margin-left: auto;
  font-size: 36px;
  span {
    display: flex;
    justify-content: center;
  }
  span:first-of-type {
    cursor: pointer;
    text-decoration: underline;
    font-size: large;
  }
}

.coupon {
  cursor: pointer;
    text-decoration: underline;
    font-size: large;
    color: rgb(71, 70, 70);
}

.button-color {
  color: #6c757d;
}


.pay-button-span, 
.continue-button-span {
  // margin: 0;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button{
    background-color: #6c757d;
  }
}

.set-alignment {
  width: 100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.button-size {
  width: fit-content;
}

.set-checkout-margin {
  display: flex;
  justify-content: center;  /* Align Horizontally */
  align-items: center; 
}