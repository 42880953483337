#user-list-paper {
    margin-top: 10px;
    margin-bottom: 20px;
}

.search {
    float: right;
    div {
        width: auto;
    }
}

.search-input {
    margin-left: auto;
    div {
        margin: 0;
    }
}

.user-Purchase-list {
    margin-top: 30px;
    margin-bottom: 30px;
}

.delivery-status-icon:hover {
    cursor: pointer;
}

// Receipt Button
.generate-receipt {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 5px 8px;
}

// Receipt Modal
.receipt-modal-body {
    border-top: 1px solid #ebe8e8;
}

.receipt-modal-row {
    background-color: #f1eded;
}

.purchase-total-row {
    border-top: 1px dotted #ebe8e8;
    margin-top: 5px;
    margin-bottom: 5px;
}

.mode {
    margin-top: 5px;
}

.mode-label {
    padding-top: 5px;
}
.select-mode {
    border: 1px solid #ebe8e8;
    border-radius: 4px;
    padding: 5px 8px;
    box-shadow: none;
}

.pdf-icon:hover {
    cursor: pointer;
}