.center-item {
    display: flex;
    justify-content: center;
    margin-top:60px;
}

.center-multi-select {
    margin-top: 34px;
}

.add-detail-field:hover {
    cursor: pointer;
}

.buttons{
    display: flex;
    justify-content:flex-start;
    margin-bottom: 30px;
}

.add-leftMargin {
    margin-left: 30px;
}

#dropdown-basic-button {
    color: white;
    background-color: #AEAEAE;
    border-color: #AEAEAE;
}

.group .form-input-label {
    
}

@media only screen and (max-width: 480px){
    .group .form-input-label {
        font-size: 12px;
    }
    .buttons > button {
        min-width: 45%;
    }
}
#timePicker > div, 
#datePicker > div {
    position: relative;
    margin: 45px 0; 
    width: 100%;
}

.center-item > button {
    width: 160px;
    font-size: small;
}

// .padding-top {
//     @media (min-width: 360px) and (max-width: 448px) {
//         padding-top: 10px;
//     }
// }