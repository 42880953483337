.client-image {
    display: flex;
    justify-content:space-between;
    align-items: center;
    overflow: hidden;
}

.client-image> img {
    flex-shrink: 0;
}


@media (min-width: 320px) and (max-width: 360px){
    .bannerImages {
        width: 3rem;
    }
    .client-image {
        // width: 90vw;
    }
}

@media (min-width: 361px) and (max-width: 400px){
    .bannerImages {
        width: 3rem;
    }
    .client-image {
        // width: 90vw;
    }
}

@media (min-width: 401px) and (max-width: 450px){
    .bannerImages {
        width: 3.8rem;
    }
    .client-image {
        // width: 90vw;
    }
}

@media (min-width: 451px) and (max-width: 500px){
    .bannerImages {
        width: 4rem;
    }
    .client-image {
        // width: 90vw;
    }
}
@media (min-width: 501px) and (max-width: 550px){
    .bannerImages {
        width: 4rem;
    }
    .client-image {
        // width: 90vw;
    }
}

@media (min-width: 551px) and (max-width: 599px){
    .bannerImages {
        width: 4.5rem;
    }
    .client-image {
        // width: 90vw;
    }
}
@media (min-width: 600px) and (max-width: 650px){
    .bannerImages {
        width: 5rem;
    }
    .client-image {
        // width: 90vw;
    }
}
@media (min-width: 651px) and (max-width: 700px){
    .bannerImages {
        width: 5.5rem;
    }
    .client-image {
        // width: 90vw;
    }
}
@media (min-width: 701px) and (max-width: 750px){
    .bannerImages {
        width: 6rem;
    }
    .client-image {
        // width: 90vw;
    }
}
@media (min-width: 751px) and (max-width: 800px){
    .bannerImages {
        width: 6.5rem;
    }
    .client-image {
        // width: 90vw;
    }
}
@media (min-width: 801px) and (max-width: 850px){
    .bannerImages {
        width: 7.8rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 851px) and (max-width: 900px){
    .bannerImages {
        width: 7.8rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 901px) and (max-width: 950px){
    .bannerImages {
        width: 8.5rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 951px) and (max-width: 1000px){
    .bannerImages {
        width: 9rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1001px) and (max-width: 1050px){
    .bannerImages {
        width: 9rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1051px) and (max-width: 1100px){
    .bannerImages {
        width: 9.5rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1101px) and (max-width: 1150px){
    .bannerImages {
        width: 10rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1151px) and (max-width: 1200px){
    .bannerImages {
        width: 10.5rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1201px) and (max-width: 1250px){
    .bannerImages {
        width: 11rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1251px) and (max-width: 1300px){
    .bannerImages {
        width: 11.5rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1301px) and (max-width: 1350px){
    .bannerImages {
        width: 12rem;
    }
    .client-image {
        // width: 93vw;
    }
}
@media (min-width: 1351px) and (max-width: 1400px){
    .bannerImages {
        width: 12.5rem;
    }
    .client-image {
        // width: 93vw;
    }
}
